<template>
  <div
    :id="originalAttrs && originalAttrs.id ? originalAttrs.id : segmentId"
    class="flex gap-5 translation-block"
    :class="{
      titleeditor_translation: isTitle,
      selected: selected
    }"
  >
    <div class="border-r border-gray-200 pr-5 flex-1 min-w-0 py-1">
      <div class="relative pt-0 pb-4 px-4 bg-gray-100 original-block">
        <div class="flex h-10 font-semibold text-sm mb-0 gap-2 items-center block-heading">
          <BlockIcon :type="isTitle ? 'heading-1' : segmentType" />
          B{{ segmentNumber }}
          <div class="flex-1"></div>
          <b-tooltip
            v-if="
              editable &&
                ((isTitle && originalText && originalText.length > 0) ||
                  (originalHtml && (segmentType == 'paragraph' || segmentType == 'title')) ||
                  editorOriginal)
            "
            type="is-light"
            :class="{
              'mr-36 -mb-1': segmentType == 'image',
              '-mr-4': segmentType != 'image'
            }"
            :label="$t('import_content')"
          >
            <fw-button size="sm" type="xlight" @click.native="importFromOriginal()">
              A <fw-icon-arrow-right class="w-4 h-4" />
            </fw-button>
          </b-tooltip>
        </div>
        <div
          v-if="isTitle && originalText && originalText.length > 0"
          class="opacity-60 pt-px min-h-6"
          v-html="originalText"
        ></div>
        <div
          v-else-if="originalHtml && (segmentType == 'paragraph' || segmentType == 'title')"
          v-html="originalHtml"
        ></div>
        <editor-content v-else-if="editorOriginal" :editor="editorOriginal" />
        <div v-else class="opacity-10 pt-px">{{ $t('no_content') }}</div>
      </div>
    </div>
    <div class="flex-shrink-0 w-3/5 pt-1.5 pb-1.5 px-2 bg-white my-1 translated-block" @click="selectBlock()">
      <div class="flex px-2 font-semibold text-sm pb-0.5 -mb-px gap-2 items-center block-heading">
        <BlockIcon :type="isTitle ? 'heading-1' : segmentType" />
        B{{ segmentNumber }} {{ isDebug ? ' - ' + segmentId : '' }}
        <div class="flex-1"></div>
        <b-dropdown
          v-if="autoTranslationOptions.length == 1 && autoTranslationAvailable && editable && !nocontent"
          aria-role="list"
          :scrollable="true"
          :max-height="270"
          position="is-bottom-left"
        >
          <template #trigger="{}">
            <fw-button type="link-light" :size="'xs'" :loading="loading.translation" :icon-right="'chevron-down'">
              <fw-icon-robot-3 class="w-4 h-4" />
            </fw-button>
          </template>
          <b-dropdown-item
            aria-role="listitem"
            has-link
            :focusable="false"
            class="font-medium flex w-52 items-center text-sm cursor-pointer px-1.5 py-1.5 gap-2 hover:bg-gray-100"
            @click="autoTranslate(autoTranslationOptions[0])"
          >
            <div
              class="h-6 w-6 bg-gray-200 font-semibold text-xs rounded-md flex flex-icon justify-center items-center"
            >
              AI
            </div>
            <span class="text-gray-700">{{ $t('translate_with_ai') }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-if="autoTranslationOptions.length > 1 && autoTranslationAvailable && editable && !nocontent"
          aria-role="list"
          :scrollable="true"
          :max-height="270"
          position="is-bottom-left"
        >
          <template #trigger="{}">
            <fw-button type="link-light" :size="'xs'" :loading="loading.translation" :icon-right="'chevron-down'">
              <fw-icon-robot-3 class="w-4 h-4" />
            </fw-button>
          </template>
          <fw-label class="ml-1">{{ $t('translate_to') }}:</fw-label>
          <b-dropdown-item
            v-for="(option, o) in autoTranslationOptions"
            :key="option.key"
            aria-role="listitem"
            has-link
            :focusable="false"
            class="font-medium flex w-52 items-center text-sm cursor-pointer px-1.5 py-1.5 gap-2 hover:bg-gray-100"
            :class="{
              'border-b border-gray-100': o != autoTranslationOptions.length - 1
            }"
            @click="autoTranslate(option)"
          >
            <span class="text-gray-700">{{ option.key }}</span>
            <span class="text-gray-400"> {{ option.title }} </span>
          </b-dropdown-item>
        </b-dropdown>
        <div
          class="flex gap-2 items-center"
          :class="{
            'text-primary': comments.length > 0,
            'text-gray-400': comments.length == 0
          }"
        >
          <fw-icon-discuss class="h-4 w-4" /> {{ comments.length }}
        </div>
      </div>
      <editor-content v-if="editor" class="main-text" :editor="editor" />
      <div
        v-else-if="nocontent"
        class="h-10 w-full text-sm text-gray-300 flex items-center mt-1.5 justify-start pl-2 bg-gray-50"
      >
        {{ $t('no_content') }}
      </div>
      <div v-else class="rounded-md h-10 w-full animate-pulse flex items-center justify-center bg-gray-100">
        {{ $t('loading') }}
      </div>
      <div ref="menutranslations" class="menu-one-translations editor-menu">
        <div v-if="editor" class="flex gap-1">
          <button
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleBold()
                .run()
            "
          >
            <fw-icon-bold class="h-5 w-5 text-gray-600" />
          </button>
          <button
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleItalic()
                .run()
            "
          >
            <fw-icon-italic class="h-5 w-5 text-gray-600" />
          </button>
          <button
            :class="{ 'is-active': editor.isActive('underline') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleUnderline()
                .run()
            "
          >
            <fw-icon-underline class="h-5 w-5 text-gray-600" />
          </button>
          <button
            :class="{ 'is-active': editor.isActive('strike') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleStrike()
                .run()
            "
          >
            <fw-icon-strike class="h-5 w-5 text-gray-600" />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .unsetAllMarks()
                .run()
            "
          >
            <fw-icon-format-clear class="h-5 w-5 text-gray-600" />
          </button>
          <div class="w-0.5 border-r mr-0.5 border-gray-200 my-1"></div>
          <button
            :class="{ 'is-active': editor.isActive('code') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleCode()
                .run()
            "
          >
            <fw-icon-code class="h-5 w-5 text-gray-600" />
          </button>
          <button
            :class="{ 'is-active': editor.isActive('superscript') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleSuperscript()
                .run()
            "
          >
            <fw-icon-superscript class="h-5 w-5 text-gray-600" />
          </button>
          <button
            :class="{ 'is-active': editor.isActive('subscript') }"
            @click="
              editor
                .chain()
                .focus()
                .toggleSubscript()
                .run()
            "
          >
            <fw-icon-subscript class="h-5 w-5 text-gray-600" />
          </button>
          <div class="w-0.5 border-r mr-0.5 border-gray-200 my-1"></div>
          <button :class="{ 'is-active': editor.isActive('link') }" @click="setLink()">
            <fw-icon-link class="h-5 w-5 text-gray-600" />
          </button>
          <button
            v-if="editor.isActive('link')"
            @click="
              editor
                .chain()
                .focus()
                .unsetLink()
                .run()
            "
          >
            <fw-icon-unlink class="h-5 w-5 text-gray-600" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlockIcon from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockIcon'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Focus from '@tiptap/extension-focus'
import StarterKit from '@tiptap/starter-kit'
import Collaboration from '@tiptap/extension-collaboration'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import CustomImage from '@/fw-modules/fw-core-vue/pages/components/editor/customImage'
import CustomVideo from '@/fw-modules/fw-core-vue/pages/components/editor/customVideo'
import CustomHeading from '@/fw-modules/fw-core-vue/pages/components/editor/customHeading'
import customTable from '@/fw-modules/fw-core-vue/pages/components/editor/customTable'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import Link from '@tiptap/extension-link'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import 'tippy.js/animations/shift-toward.css'
import Document from '@tiptap/extension-document'
import ServiceAi from '@/fw-modules/fw-core-vue/ai/services/ServiceAi'
import BubbleMenuExtension from '@tiptap/extension-bubble-menu'
import { isTextSelection } from '@tiptap/core'
//import { customTaskList, customTaskItem } from '@/fw-modules/fw-core-vue/pages/components/editor/customTaskList/'
export default {
  name: 'TranslationTextBlock',
  components: {
    EditorContent,
    BlockIcon
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    segmentNumber: {
      type: Number,
      required: true
    },
    segmentType: {
      type: String,
      required: true
    },
    segmentId: {
      type: String,
      required: true
    },
    originalText: {
      type: String
    },
    originalContent: {
      type: Array
    },
    originalAttrs: {
      type: Object
    },
    targetText: {
      type: String
    },
    sourceLanguage: {
      type: String,
      required: true
    },
    pageKey: {
      type: String,
      required: true
    },
    versionKey: {
      type: String,
      required: true
    },
    userColor: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isTitle: {
      type: Boolean,
      default: false
    },
    autoTranslationOptions: {
      type: Array,
      default: () => []
    },
    comments: {
      type: Array,
      default: () => []
    },
    targetProvider: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      autoTranslationCompatible: [
        'paragraph',
        'heading-1',
        'heading-2',
        'heading-3',
        'blockquote',
        'bulletList',
        'orderedList'
      ],
      editor: null,
      editorOriginal: null,
      loading: {
        translation: false,
        document: true
      },
      originalHtml: null,
      nocontent: false
    }
  },
  computed: {
    isDebug() {
      return localStorage.getItem('fw-debug') == 'true'
    },
    autoTranslationAvailable() {
      return this.autoTranslationCompatible.includes(this.segmentType) && this.autoTranslationOptions.length > 0
    },
    isHeading() {
      return this.segmentType.startsWith('heading')
    },
    headingLevel() {
      return this.isHeading ? parseInt(this.segmentType.replace('heading-', '')) : 0
    },
    currentUser() {
      return {
        name: this.me.name,
        color: this.userColor
      }
    },
    me() {
      return this.$store.state.session.user
    }
  },
  mounted() {
    console.log('mounted')
    this.awake()
  },
  methods: {
    awake() {
      //TODO
      if ((this.segmentType == 'paragraph' || this.segmentType == 'title') && this.originalHtml == null) {
        //try to generate html from the original content
        let html = ''
        if (this.originalContent) {
          this.originalContent.forEach(block => {
            if (block.type == 'text') {
              html += block.text
            } else {
              console.log('block not processed', block)
            }
          })
        }
        this.originalHtml = html
        if (this.originalHtml.trim().length == 0) {
          this.nocontent = true
        }
      } else if (this.segmentType != 'paragraph' && this.editorOriginal == null) {
        let originalEditorContent = {
          type: 'doc',
          content: [
            {
              type: this.isHeading ? 'heading' : this.segmentType
            }
          ]
        }
        if (this.originalAttrs) {
          originalEditorContent.content[0].attrs = this.originalAttrs
        }
        if (this.originalContent) {
          originalEditorContent.content[0].content = this.originalContent
        }
        //we need to use the editor
        this.editorOriginal = new Editor({
          editable: false,
          content: originalEditorContent,
          extensions: [
            Underline,
            StarterKit.configure({
              // The Collaboration extension comes with its own history handling
              paragraph: true,
              heading: true,
              history: false,
              dropcursor: false,
              link: false,
              codeBlock: true,
              bulletList: true,
              orderedList: true,
              blockquote: true,
              document: true
            }),
            Superscript,
            Subscript,
            CustomImage.configure({
              HTMLAttributes: {
                class: 'editor-image'
              }
            }),
            CustomVideo,
            customTable,
            TableRow,
            TableHeader,
            TableCell,
            Link.configure({
              openOnClick: false,
              autoLink: false
            })
          ],
          pageKey: this.pageKey,
          versionKey: this.versionKey,
          downloadImages: true
        })
      }
      if (this.editor == null && this.nocontent == false) {
        const oneBlockDocument = Document.extend({
          content: 'block'
        })

        console.log('originalEditorContent', this.originalContent)

        this.editor = new Editor({
          editable: this.editable,
          /*attrs: {
            DOMParser: this.DOMParser,
          },*/
          //content: this.targetText ? this.targetText : '',
          extensions: [
            //customTaskList,
            /*customTaskItem.configure({
              HTMLAttributes: {
                class: 'editor-task-item',
              },
            }),*/
            Focus.configure({
              //className: 'active',
              mode: 'shallowest'
            }),
            Underline,
            Placeholder.configure({
              placeholder: this.$t('write_translation_here')
              //considerAnyAsEmpty: true,
            }),
            StarterKit.configure({
              // The Collaboration extension comes with its own history handling
              paragraph: true,
              heading: false,
              history: false,
              dropcursor: false,
              link: false,
              codeBlock: true,
              bulletList: true,
              orderedList: true,
              blockquote: true,
              document: false
            }),
            BubbleMenuExtension.configure({
              tippyOptions: {
                duration: 100,
                animation: 'shift-toward',
                placement: 'top-start'
              },
              element: this.$refs.menutranslations,
              shouldShow: ({ view, state, from, to }) => {
                //{ editor, view, state, oldState, from, to }
                const { doc, selection } = state
                const { empty } = selection

                // Sometime check for `empty` is not enough.
                // Doubleclick an empty paragraph returns a node size of 2.
                // So we check also for an empty text size.
                const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection)
                //console.log('test', doc.nodeAt(from))
                const isTextBlock = isTextSelection(state.selection) //from != null && doc.nodeAt(from) != null ? doc.nodeAt(from).type.isText : false
                // When clicking on a element inside the bubble menu the editor "blur" event
                // is called and the bubble menu item is focussed. In this case we should
                // consider the menu as part of the editor and keep showing the menu
                const isChildOfMenu = document.activeElement.classList.contains('tiptap') //this.element.contains(document.activeElement)
                //console.log('isChildOfMenu', isChildOfMenu)
                const hasEditorFocus = view.hasFocus() || isChildOfMenu

                if (!hasEditorFocus || !isTextBlock || empty || isEmptyTextBlock || !this.editor.isEditable) {
                  return false
                }

                return true
                //return true //editor.isActive('bold') || editor.isActive('italic') || editor.isActive('strike')
              }
            }),
            oneBlockDocument,
            Superscript,
            Subscript,
            // Register the document with Tiptap
            Collaboration.configure({
              document: this.targetProvider.document,
              field: this.segmentId || this.segmentNumber
            }),
            CollaborationCursor.configure({
              provider: this.targetProvider,
              user: this.currentUser
            }),
            CustomImage.configure({
              HTMLAttributes: {
                class: 'editor-image'
              }
            }),
            CustomVideo,
            CustomHeading.configure({
              levels: [1, 2, 3]
            }),
            customTable,
            TableRow,
            TableHeader,
            TableCell,
            Link.configure({
              openOnClick: false,
              autoLink: false
            })
          ],
          onUpdate: ({ editor }) => {
            console.log('target editor updated', this.segmentId)
            /*console.log('target editor updated')
            //check if the editor is empty
            // this.isEmpty = editor.getText().trim().length === 0
            let json = editor.getJSON()
            //console.log('json', json)
            if (json.content.length > 1) {
              this.isEmpty = false
            } else if (
              json.content.length == 1 &&
              (typeof json.content[0].content == 'undefined' || json.content[0].content.length == 0) &&
              json.content[0].type != 'image' &&
              json.content[0].type != 'video'
            ) {
              this.isEmpty = true
            } else {
              this.isEmpty = false
            }*/
            if (this.segmentId == 'title' && editor) {
              let text = editor.getText()
              this.$emit('title-changed', text)
            }
            this.$emit('update')
          },
          onFocus: () => {
            this.selectBlock()
          },
          pageKey: this.pageKey,
          versionKey: this.versionKey
        })
      } else {
        //hide menu
        let menu = this.$refs.menutranslations
        if (menu) {
          menu.style.display = 'none'
        }
      }
    },
    sleep() {
      //TODO clean resources
      if (this.editor != null) {
        this.editor.destroy()
        this.editor = null
      }
      if (this.editorOriginal != null) {
        this.editorOriginal.destroy()
        this.editorOriginal = null
      }
    },
    setEditorContent(content) {
      this.editor.commands.setContent(content)
    },
    createContentFromOriginal(replace = true) {
      let cleanContent = null
      if (this.originalContent) {
        let removeTextBlocks = content => {
          content.forEach((block, index) => {
            if (block.type == 'text') {
              content.splice(index, 1)
            } else if (block.content) {
              removeTextBlocks(block.content)
            }
          })
        }
        cleanContent = JSON.parse(JSON.stringify(this.originalContent))
        if (replace) {
          removeTextBlocks(cleanContent)
        }
        //console.log('cleanContent', cleanContent)
      } else if (this.originalText) {
        cleanContent = [
          {
            type: 'text',
            text: this.originalText
          }
        ]
      }
      let attrs = this.originalAttrs ? JSON.parse(JSON.stringify(this.originalAttrs)) : {}
      if (attrs.src && replace) {
        attrs.src = null
      }
      //console.log('set attrs', attrs)
      //console.log('set content', cleanContent)
      //loop through the content and remove text blocks up to 3 levels deeo
      this.editor.commands.setContent({
        type: 'doc',
        content: [
          {
            type: this.isHeading ? 'heading' : this.segmentType,
            attrs: attrs,
            content: cleanContent ? cleanContent : []
          }
        ]
      })
      setTimeout(() => {
        this.$emit('update')
      }, 500)
    },
    selectBlock() {
      let text = this.editor ? this.editor.getText() : ''
      //count words
      let numberWords = text.split(' ').filter(function(str) {
        return str != ''
      }).length
      //count characters
      let numberCharacters = text.replace(/ /g, '').length
      this.$emit('select-block', {
        segmentId: this.segmentId,
        segmentNumber: this.segmentNumber,
        type: this.segmentType,
        words: numberWords,
        characters: numberCharacters
      })
    },
    importFromOriginal() {
      let isEmpty = this.editor.getText().trim().length === 0
      if (!isEmpty) {
        this.$buefy.dialog.confirm({
          title: 'Importar conteúdo do original',
          message: 'Tem a certeza que deseja substituir o texto deste segmento pelo texto original?',
          confirmText: 'Sim',
          cancelText: 'Não',
          type: 'is-info',
          hasIcon: false,
          onConfirm: () => {
            this.createContentFromOriginal(false)
          }
        })
      } else {
        this.createContentFromOriginal(false)
      }
    },
    autoTranslate(option) {
      let isEmpty = this.editor.getText().trim().length === 0
      //verify if the editor is empty
      if (!isEmpty) {
        this.$buefy.dialog.confirm({
          title: this.$t('title_autotranslation'),
          message: this.$t('title_autotranslation_message'),
          confirmText: this.$t('title_autotranslation_confirm'),
          cancelText: this.$t('title_autotranslation_cancel'),
          type: 'is-info',
          hasIcon: false,
          onConfirm: () => {
            this.executeTranslation(option)
          }
        })
      } else {
        this.executeTranslation(option)
      }
    },
    async executeTranslation(option) {
      try {
        this.loading.translation = true
        let originalText = this.originalText
          ? this.originalText
          : this.editorOriginal
          ? this.editorOriginal.getHTML()
          : this.originalHtml
          ? this.originalHtml
          : ''
        let result = await ServiceAi.translate(this.sourceLanguage, option.key, originalText)
        //console.log('result', result)
        //let type = this.segmentType
        this.editor.commands.clearContent()
        this.editor.commands.setContent(result.data)
        /*this.editor.commands.setContent({
          type: type.startsWith('heading') ? 'heading' : type,
          attrs: this.originalAttrs ? this.originalAttrs : {},
          content: [
            {
              type: 'text',
              text: result.data,
            },
          ],
        })*/
        setTimeout(() => {
          this.$emit('update')
        }, 500)
      } catch (error) {
        console.error('error', error)
      } finally {
        this.loading.translation = false
      }
    },
    getText() {
      if (this.editor != null) {
        return this.editor.getText()
      } else {
        return ''
      }
    },
    getTipTapJSON() {
      if (this.editor != null) {
        return this.editor.getJSON()
      }
    },
    getHtml() {
      if (this.editor != null) {
        return this.editor.getHTML()
      } else {
        return ''
      }
    }
  },
  beforeUnmount() {
    this.sleep()
  }
}
</script>

<style>
.translation-block pre code {
  border: none !important;
  background: none !important;
}
.editor-menu {
  @apply flex gap-1 rounded-md py-0.5 px-1 border border-gray-200 shadow-sm;
  background-color: #fafafa;
}
.editor-menu button {
  @apply border border-transparent bg-gray-50 rounded-md px-0.5 h-8 w-8 text-center flex items-center justify-center opacity-80;
}

.editor-menu button:hover {
  @apply opacity-100;
}
.editor-menu button.is-active {
  @apply opacity-100 bg-gray-200 border-gray-300;
}

.titleeditor_translation {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}
.translation-block .original-block {
  @apply border border-transparent;
}
.translation-block.selected .original-block {
  @apply border-gray-200;
}
.translation-block .translated-block {
  @apply border border-transparent;
}
.translation-block.selected .translated-block {
  @apply border-primary border-opacity-50;
}
.translation-block .block-heading {
  @apply text-gray-500;
}
.translation-block.selected .block-heading {
  @apply text-primary;
}
.original-block .ProseMirror {
  background-color: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
}
</style>

<i18n>
  {
    "pt": {
      "no_content": "Sem conteúdo",
      "translate_to": "Traduzir para",
      "write_translation_here": "Escreva a tradução aqui",
      "title_autotranslation": "Tradução automática",
      "title_autotranslation_message": "Tem a certeza que deseja substituir o texto deste segmento por um de tradução automática?",
      "title_autotranslation_confirm": "Sim",
      "title_autotranslation_cancel": "Não",
      "translate_with_ai": "Traduzir com IA",
      "import_content": "Usar conteúdo original",
      "loading": "A carregar..."
    },
    "en": {
      "no_content": "No content",
      "translate_to": "Translate to",
      "write_translation_here": "Write the translation here",
      "title_autotranslation": "Automatic translation",
      "title_autotranslation_message": "Are you sure you want to replace the text of this segment with an automatic translation?",
      "title_autotranslation_confirm": "Yes",
      "title_autotranslation_cancel": "No",
      "translate_with_ai": "Translate with AI",
      "import_content": "Use original content",
      "loading": "Loading..."
    }
  }
</i18n>
