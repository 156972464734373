import DraggableBlock from '../editor/DraggableBlock.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Heading from '@tiptap/extension-heading'
const customParagraph = Heading.extend({
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(DraggableBlock)
  }
})
export default customParagraph
